// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ERP_HOME: "https://erp.pmt.com.ng",
  WEBAPP: "https://pmt.com.ng",
  SHOP: "https://shop.pmt.com.ng",
  EWALLET: "https://ewallet.pmt.com.ng",
  TRANSPORT: "https://transport.pmt.com.ng",
  LOGISTIC: "https://logistic.pmt.com.ng",
  ENTERPRISE: "https://enterprise.pmt.com.ng",
  SUPPORT: "https://support.pmt.com.ng",
  PML_AGENT: "https://pml-agent.pmt.com.ng",
  PMT_AGENT: "https://pmt-agent.pmt.com.ng",
  ERP_LOGISTIC: "https://erp-logistic.pmt.com.ng",
  ERP_TRANSPORT: "https://erp-transport.pmt.com.ng",
  ERP_FINANCE: "https://erp-finance.pmt.com.ng",
  ERP_HR: "https://erp-hr.pmt.com.ng",
  ERP_ADMIN: "https://erp-admin.pmt.com.ng",
  ERP_WORKSHOP: "https://erp-workshop.pmt.com.ng",
  ERP_ECOMMERCE: "https://erp-ecommerce.pmt.com.ng",
  ERP_GENERAL: "https://erp-general.pmt.com.ng",
  ERP_EWALLET: "https://erp-ewallet.pmt.com.ng",
  PARK: "https://park.pmt.com.ng",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
