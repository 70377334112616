<div class="container-fluid">
  <!-- Background animtion-->
  <div class="background">
    <div class="cube"></div>
    <div class="cube"></div>
    <div class="cube"></div>
    <div class="cube"></div>
    <div class="cube"></div>
  </div>

    <div class="parent">

      <div class="childheading">
          <h3>PEACEGROUP ERP 4.0</h3>
      </div>
      <div class="child"><a [href]="ERP_ADMIN" target="_blank" class="home_btn">ERP 2.0</a></div>
      <div class="child"><a [href]="WEBAPP" target="_blank" class="home_btn">WEBAPP 4.0</a></div>
      <div class="child"><a [href]="EWALLET" target="_blank" class="home_btn">EWALLET</a></div>
      <div class="child"><a [href]="ERP_EWALLET" target="_blank" class="home_btn">ERP EWALLET</a></div>
      <div class="child"><a [href]="SHOP" target="_blank" class="home_btn">SHOP</a></div>
      <div class="child"><a [href]="ERP_ECOMMERCE" target="_blank" class="home_btn">ECOMMERCE</a></div>
      <div class="child"><a [href]="TRANSPORT" target="_blank" class="home_btn ext">PMT CONSOLE</a></div>
      <div class="child"><a [href]="LOGISTIC" target="_blank" class="home_btn ext">PML CONSOLE</a></div>
      <div class="child"><a [href]="ENTERPRISE" target="_blank" class="home_btn ext">GRASSROOT</a></div>
      <div class="child"><a [href]="PARK" target="_blank" class="home_btn ext">PARK CONSOLE</a></div>
      <div class="child"><a [href]="PML_AGENT" target="_blank" class="home_btn ext">PML AGENT</a></div>
      <div class="child"><a [href]="PMT_AGENT" target="_blank" class="home_btn ext">PMT AGENT</a></div>
      <div class="child"><a [href]="ERP_LOGISTIC" target="_blank" class="home_btn">ERP PML</a></div>
      <div class="child"><a [href]="ERP_TRANSPORT" target="_blank" class="home_btn">ERP PMT</a></div>
      <div class="child"><a [href]="ERP_FINANCE" target="_blank" class="home_btn">ERP FINANCE</a></div>
      <div class="child"><a [href]="ERP_HR" target="_blank" class="home_btn">ERP HR</a></div>
      <div class="child"><a [href]="ERP_WORKSHOP" target="_blank" class="home_btn">WORKSHOP</a></div>
      <div class="child"><a [href]="ERP_GENERAL" target="_blank" class="home_btn">GENERAL</a></div>
      <div class="child"><a [href]="SUPPORT" target="_blank" class="home_btn int">SUPPORT</a></div>
    </div>

</div>
