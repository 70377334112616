import { Component, OnInit } from '@angular/core';
import { environment as ENV } from '../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  SHOP = ENV.SHOP;
  WEBAPP = ENV.WEBAPP;
  TRANSPORT = ENV.TRANSPORT;
  LOGISTIC = ENV.LOGISTIC;
  ENTERPRISE = ENV.ENTERPRISE;
  PML_AGENT = ENV.PML_AGENT;
  PMT_AGENT = ENV.PMT_AGENT;
  ERP_LOGISTIC = ENV.ERP_LOGISTIC;
  ERP_TRANSPORT = ENV.ERP_TRANSPORT;
  ERP_FINANCE = ENV.ERP_FINANCE;
  ERP_HR = ENV.ERP_HR;
  ERP_ADMIN = ENV.ERP_ADMIN;
  ERP_WORKSHOP = ENV.ERP_WORKSHOP;
  ERP_ECOMMERCE = ENV.ERP_ECOMMERCE;
  ERP_GENERAL = ENV.ERP_GENERAL;
  ERP_EWALLET = ENV.ERP_EWALLET;
  EWALLET = ENV.EWALLET;
  SUPPORT = ENV.SUPPORT;
  PARK = ENV.PARK;

  constructor() { }

  ngOnInit(): void {
  }

}
